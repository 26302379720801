













































































































































import {Component, Watch} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';
import VcSwitch from '@/components/VCSwitch.vue';
import {AwaySetting, WelcomeSetting} from '@/domain/model/types';
import {settingsStore} from '@/store/modules/settings';
import rfdc from 'rfdc';
const clone = rfdc({proto: true})

@Component({
  name: 'settings-away-messages',
  components: {VcSwitch}
})
export default class AwayMessages extends mixins(Notifications) {
  away: AwaySetting = {
    active: true,
    closedMessage: null,
    awayMessage: null
  }

  welcome: WelcomeSetting = {
    active: true,
    welcomeMessage: null
  }

  // @Watch('away', {deep: true, immediate: false})
  // onAwayChanged(after, before) {
  //   this.checkDisabled()
  // }
  //
  // checkDisabled() {
  //   this.disabled = this.compareChange() ? false : true
  // }

  compareChange() {
    return true
  }

  async onSave() {
    if (!this.compareAway()) {
      await settingsStore.changeAway(clone(this.away))
    }
    if (!this.compareWelcome()) {
      await settingsStore.changeWelcome(clone(this.welcome))
    }
  }

  compareAway() {
    return JSON.stringify(this.away) === JSON.stringify(settingsStore.businessSettings?.away)
  }

  compareWelcome() {
    return JSON.stringify(this.welcome) === JSON.stringify(settingsStore.businessSettings?.welcome)
  } 

  get disabled() {    
    return (this.compareAway() && this.compareWelcome())
  }

  onDiscard() {
    this.init()    
  }

  init() {
    try{
      this.away = clone(settingsStore.businessSettings?.away)  
      this.welcome = clone(settingsStore.businessSettings?.welcome)   

      this.away.active = settingsStore.businessSettings?.away?.active;
      this.welcome.active = settingsStore.welcome.active;      
    } catch (e) {        
      console.error("Error in AwayMessage init");
      console.error(e);
    }  
  }

  created() {
    this.init()
  }
}
